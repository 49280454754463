import { EmailIcon } from "@chakra-ui/icons";
import { Box, Center, Stack, Text } from "@chakra-ui/react";

function SingupSuccessGoToMailPromptPage() {
  return (
    <Box
      maxW={{ base: "100%", md: "900px" }}
      minH={{ base: "90%", md: "200px" }}
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={4}
      boxShadow="lg"
    >
      <Box mb={4} display="flex" alignItems="center">
        <EmailIcon boxSize={10} mr={2} color={"#3DC6F1"}/>
        <Text fontSize="3xl" fontWeight="bold" color={"#3DC6f1"}>
          You have successfully created an account with Ensight!
        </Text>
      </Box>
      <Text mt={10} fontSize="xl" fontWeight="bold">
        Next, go to your email and follow the link we've sent you to continue.
        After you have verified your email, we can proceed with registering your
        account details.
      </Text>
    </Box>
  );
}

export default SingupSuccessGoToMailPromptPage;
