import { useToast } from "@chakra-ui/toast";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import authUserApi from "../utils/authUserApi";
export default function useUserSignUpHook(): any {
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  function showMessage(
    errMsg: string,
    status:
      | "info"
      | "warning"
      | "success"
      | "error"
      | "loading"
      | undefined = "error"
  ) {
    toast({
      title: errMsg,
      position: "top-right",
      isClosable: true,
      status: status,
    });
  }

  function createRequestBody(params: any): string {
    let resp = "";
    switch (params.url) {
      case "auth-service/auth/register":
        resp = JSON.stringify({
          email: params.email,
          password: params.password,
          role: params.role,
          ConfirmationEmailUrl: params.ConfirmationEmailUrl
        });
        break;
      case "auth-service/auth/forgetpassword":
        resp = JSON.stringify({
          email: params.email,
        });
        break;
      case "/auth-service/auth/changepassword":
        resp = JSON.stringify({
          key: params.token,
          email: params.email,
          password: params.password,
        });
      case "user-service/supply":
        resp = JSON.stringify({
          UserDetailsId: params.userDetailsId,
          Number: params.number,
          Address: params.address,
          ZipCode: params.zipCode,
          Type: params.type,
          nickname: params.nickname,
          Externalnickname: ''
        });
        break;
      case "/auth-service/auth/confirmationemail":
        resp = JSON.stringify({
          key: params.key,
        });
        break;
      case "auth-service/auth/login":
        resp = JSON.stringify({
          email: params.email,
          password: params.password,
        });
        break;
      case "user-service/userdetails":
        resp = JSON.stringify({
          address: params.address,
          authId: params.authId,
          email: params.email,
          name: params.name,
          country: params.country,
          orgOrEspAccountId: params.orgOrEspAccountId,
          role: params.role,
          status: params.status,
          tin: params.tin,
          userType: params.userType,
          zipCode: params.zipCode,
        });
        break;
      default:
        break;
    }
    return resp;
  }

  function cleanlocalStorage() {
    localStorage.clear();
  }

  function writeTolocalStorage(params: any) {
    if (params.authToken) {
      localStorage.setItem("userOnBoardingAuthToken", params.authToken);
      localStorage.setItem("userOnBoardingEmail", params.email);
      localStorage.setItem("userOnBoardingAuthId", params.id);
    } else {
      localStorage.setItem("userOnBoardingUserRole", params?.role);
      localStorage.setItem("userOnBoardingUserDetailsId", params?.id);
      localStorage.setItem("userOnBoardingEmail", params?.email);
    }
  }

  function fetchProvidersListRequest() {
    async function init() {
      try {
        setLoading(true);
        await authUserApi
          .get<any>("user-service/organizationAccountDetails", {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("userOnBoardingAuthToken")}`,
            },
          })
          .then((response) => {
            if (response) {
              setData(response.data);
            }
          })
          .catch((err) => {
            setError(err);
            setLoading(false);
            showMessage(err?.message);
          });
      } catch (e: any) {
        setError(e);
        setLoading(false);
        showMessage(e?.message);
      } finally {
        setLoading(false);
      }
    }
    init();
  }

  function makeServiceRequest(params: any) {
    if (!params) {
      return;
    }
    async function init() {
      try {
        setLoading(true);
        await authUserApi
          .post<any>(params.url, createRequestBody(params), {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("userOnBoardingAuthToken")}`,
            },
          })
          .then((response) => {
              if (response.data) {
                setData(response.data);
                writeTolocalStorage(response.data);
                if (params?.navigateTo && params?.navigateTo != "" && !response.data.errors) {
                  navigate(params.navigateTo, {
                    state: { message: `${params?.successMessage}` },
                  });
                }
                if (params.url == "forgot-password") {
                  showMessage(
                    `We have send you an email with instructions in ${params.email}`,
                    "info"
                  );
                }
                if (params.url == "reset-password") {
                  showMessage(`Password updated!`, "success");
                  cleanlocalStorage();
                }
                if (params.url == "verify") {
                  cleanlocalStorage();
                }
                if(params.url=='auth-service/auth/register'){
                  showMessage(
                    `We have send you an email with a verification code. Please check your email: ${params.email.toString()} to continue `,
                    "info"
                  );
                  const timer = setTimeout(() => {
                    navigate("/user-signup-success-goto-mail",{replace:true});
                    clearTimeout(timer);
                  }, 3000);
                  
                }
              }
          })
          .catch((err) => {
              setError(err);
              setLoading(false);
              //in the case of register and login, if the mail exists get the error message from the response body
              showMessage(err?.response?.data || err?.message);
          });
      } catch (e: any) {
          setError(e);
          setLoading(false);
          showMessage(e?.message);
      } finally {
          setLoading(false);
      }
    }
    init();
  }

  return {
    makeServiceRequest,
    fetchProvidersListRequest,
    data,
    error,
    loading,
  };
}
