import { useEffect, useRef, useState } from "react";
import {
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  FormControl,
  useToast,
  FormLabel,
  Select,
} from "@chakra-ui/react";
import { FaUserAlt, FaLock } from "react-icons/fa";
import useUserSignUpHook from "../../hooks/useAuthService";

const CFaUserAlt = chakra(FaUserAlt);

function AddSupplyConnectWithUser() {
  const errRef = useRef<HTMLParagraphElement>(null);
  const [supplyNumber, setSupplyNumber] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [address, setAdress] = useState("");
  const [userType, setUserType] = useState(0);
  const [supplyNickname, setSupplyNickname] = useState("");
  const { makeServiceRequest, data, loading, error } = useUserSignUpHook();
  const toast = useToast();

  function showMessage(
    errMsg: string,
    status:
      | "info"
      | "warning"
      | "success"
      | "error"
      | "loading"
      | undefined = "error"
  ) {
    toast({
      title: errMsg,
      position: "top-right",
      isClosable: true,
      status: status,
    });
  }

  function handleSubmit(e: any, params: any) {
    e.preventDefault();
    makeServiceRequest(params);
    errRef.current !== null ? errRef.current.focus() : "";
  }

  return (
    <>
      <Heading
        color="#5AC4E5"
        fontFamily="monospace"
        fontWeight="bold"
        mb="4"
      >Add customer Supply</Heading>
      <Stack flexDir="column" justifyContent="center" alignItems="center">
        <Box maxW={{ base: "90%", md: "468px" }}>
          <p
            ref={errRef}
            style={{ color: error ? "red" : "inherit" }}
            aria-live="assertive"
          >
            {error?.response?.data?.errors
              ? error?.response?.data?.errors
              : null}
          </p>
        </Box>
        <Box minW={{ base: "90%", md: "468px" }}>
          <form
            onSubmit={(e) => {
              handleSubmit(e, {
                url: "user-service/supply",
                userDetailsId: localStorage.getItem("userOnBoardingUserDetailsId"),
                number: supplyNumber,
                address: address,
                zipCode: zipCode,
                type: userType,
                nickname: supplyNickname,
                navigateTo: "/user-signup-success",
              });
            }}
          >
            <Stack
              spacing={4}
              p="1rem"
              backgroundColor="whiteAlpha.900"
              boxShadow="md"
            >
              <FormControl>
                <FormLabel mb="8px">Supply Number</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<CFaUserAlt color="gray.300" />}
                  />
                  <Input
                    type="number"
                    required
                    placeholder=""
                    onChange={(e) => setSupplyNumber(e.target.value.toString())}
                    value={supplyNumber}
                  />
                </InputGroup>
              </FormControl>

              <FormControl>
                <FormLabel mb="8px">Supply Nickname</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<CFaUserAlt color="gray.300" />}
                  />
                  <Input
                    type="text"
                    required
                    placeholder=""
                    onChange={(e) =>
                      setSupplyNickname(e.target.value.toString())
                    }
                    value={supplyNickname}
                  />
                </InputGroup>
              </FormControl>

              <FormControl>
                <FormLabel mb="8px">Supply Adress</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<CFaUserAlt color="gray.300" />}
                  />
                  <Input
                    type="text"
                    required
                    placeholder=""
                    onChange={(e) => setAdress(e.target.value.toString())}
                    value={address}
                  />
                </InputGroup>
              </FormControl>

              <FormControl>
                <FormLabel mb="8px">Zip Code</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<CFaUserAlt color="gray.300" />}
                  />
                  <Input
                    type="text"
                    required
                    placeholder="zip code"
                    onChange={(e) => setZipCode(e.target.value)}
                    value={zipCode}
                  />
                </InputGroup>
              </FormControl>

              <FormControl>
                <FormLabel mb="8px">User type</FormLabel>
                <InputGroup>
                  <Select
                    value={userType}
                    required
                    placeholder=""
                    onChange={(e) => setUserType(parseInt(e.target.value))}
                  >
                    <option value="1">consumer</option>
                    {/* <option value="0">Operator User</option> */}
                  </Select>
                </InputGroup>
              </FormControl>

              <Button
                isLoading={loading}
                loadingText="Submitting"
                borderRadius={0}
                type="submit"
                variant="solid"
                _hover={{ background: "#14A0BF" }}
                background={'#5AC4E5'}
                colorScheme="teal"
                width="full"
              >
                connect my supply
              </Button>
            </Stack>
          </form>
        </Box>
      </Stack>
    </>
  );
}

export default AddSupplyConnectWithUser;
