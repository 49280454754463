import { useEffect, useRef, useState } from "react";
import {
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  FormControl,
  InputRightElement,
  useToast,
} from "@chakra-ui/react";
import { FaUserAlt, FaLock } from "react-icons/fa";
import useUserSignUpHook from "../../hooks/useAuthService";

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

function UserLoginPage() {
  const [showPassword, setShowPassword] = useState(false);
  const handleShowClick = () => setShowPassword(!showPassword);
  const errRef = useRef<HTMLParagraphElement>(null);
  const [email, setEmail] = useState("");
  const [password, setPwd] = useState("");
  const { makeServiceRequest, data, loading, error } = useUserSignUpHook();
  const toast = useToast();

  function showMessage(
    errMsg: string,
    status:
      | "info"
      | "warning"
      | "success"
      | "error"
      | "loading"
      | undefined = "error"
  ) {
    toast({
      title: errMsg,
      position: "top-right",
      isClosable: true,
      status: status,
    });
  }

  function handleSubmit(e: any, params: any) {
    e.preventDefault();
    makeServiceRequest(params);
    errRef.current !== null ? errRef.current.focus() : "";
  }

  return (
    <>
      <Heading
        color="#5AC4E5"
        fontFamily="monospace"
        fontWeight="bold"
        mb="4">
            Customer Loging
        </Heading>
      <Stack flexDir="column" justifyContent="center" alignItems="center">
        <Box maxW={{ base: "90%", md: "468px" }}>
          <p
            ref={errRef}
            style={{ color: error ? "red" : "inherit" }}
            aria-live="assertive"
          >
            {error?.response?.data?.errors
              ? error?.response?.data?.errors
              : null}
          </p>
        </Box>
        <Box minW={{ base: "90%", md: "468px" }}>
          <form
            onSubmit={(e) => {
              handleSubmit(e, {
                url: "auth-service/auth/login",
                email: email,
                password: password,
                navigateTo: '/create-user-profile'
              });
            }}
          >
            <Stack
              spacing={4}
              p="1rem"
              backgroundColor="whiteAlpha.900"
              boxShadow="md"
            >
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<CFaUserAlt color="gray.300" />}
                  />
                  <Input
                    type="email"
                    required
                    placeholder="email address"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    children={<CFaLock color="gray.300" />}
                  />
                  <Input
                    type={showPassword ? "text" : "password"}
                    required
                    placeholder="Password"
                    onChange={(e) => setPwd(e.target.value)}
                    value={password}
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                      {showPassword ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <Button
                isLoading={loading}
                loadingText="Submitting"
                borderRadius={0}
                type="submit"
                variant="solid"
                _hover={{ background: "#14A0BF" }}
                background={'#5AC4E5'}
                colorScheme="teal"
                width="full"
              >
                Log In
              </Button>
            </Stack>
          </form>
        </Box>
      </Stack>
    </>
  );
}

export default UserLoginPage;
