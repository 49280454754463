import { Flex, Stack, Image } from "@chakra-ui/react";
import { ReactNode } from "react";
import logofinaltopdown from "../../assets/logos/logofinaltopdown.png";

function UserOnboardingLayout({ children }: { children: ReactNode }) {
  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="gray.200"
      justifyContent="center"
      alignItems="center"
      overflow={"auto"}
    >
      <Stack flexDir="column" justifyContent="center" alignItems="center">
        <Image
          src={logofinaltopdown}
          alt="ensight logo"
          boxSize="30%"
          height={"335px"}
          width={"345px"}
          objectFit="contain"
        />
        {children}
      </Stack>
    </Flex>
  );
}

export default UserOnboardingLayout;
