import React from "react";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import * as ReactDOM from "react-dom/client";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import NotFound from "./pages/not-found/NotFound.page";
import ResetPassword from "./pages/reset-password/Reset-password.page";
import UserOnboardingLayout from "./pages/layout/layout.page";
import WelcomePage from "./pages/welcome-page/welcome.page";
import UserSignUpPage from "./pages/signup/User-signup.page";
import OnboardingSuccessPage from "./pages/success-page/success.page";
import UserDetailsPage from "./pages/user-details/userDetails.page";
import AddSupplyConnectWithUser from "./pages/addSupply/addSupplyConnectWithUser.page";
import VerifyAccount from "./pages/verify-account/Verify-account.page";
import UserLoginPage from "./pages/login-after-verify/login-page";
import SingupSuccessGoToMailPromptPage from "./pages/signup-success-goto-mail-page/signup-success-goto-mail-prompt-page";

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container as Element);
const activeLabelStyles = {
  transform: "scale(0.85) translateY(-24px)",
};
const theme = extendTheme({
  components: {
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label":
              {
                ...activeLabelStyles,
              },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: "absolute",
              backgroundColor: "white",
              pointerEvents: "none",
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: "left top",
            },
          },
        },
      },
    },
  },
});

const App = () => (
  <Routes>
    <Route path="/" element={<UserOnboardingLayout children={<Outlet />} />}>
      <Route path="/" element={<WelcomePage />} />
      <Route path="signup-user" element={<UserSignUpPage />} />
      <Route path="verify-user" element={<VerifyAccount />} />
      <Route path="login-user" element={<UserLoginPage />} />
      <Route path="create-user-profile" element={<UserDetailsPage />} />
      <Route path="create-supply" element={<AddSupplyConnectWithUser />} />
      <Route path="reset-user-password" element={<ResetPassword />} />
      <Route path="user-signup-success" element={<OnboardingSuccessPage />} />
      <Route path="user-signup-success-goto-mail" element={<SingupSuccessGoToMailPromptPage />} />
    </Route>
    <Route path="*" element={<NotFound />} />
  </Routes>
);

root.render(
  <ChakraProvider theme={theme}>
      <BrowserRouter>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
      </BrowserRouter>
  </ChakraProvider>
);
export default React.memo(App);
