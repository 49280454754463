import {
  Heading,
  Text,
  useColorModeValue,
  Box,
  Stack,
  Button,
  InputRightElement,
  InputGroup,
  Input,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function WelcomePage() {
  useEffect(() => {
    localStorage.clear();
  }, []);
  const navigate = useNavigate();
  return (
    <>
    <VStack>
      <Heading
        h={'60%'}
        color="#5AC4E5"
        variant="brand"
        as="h1"
        size="4xl"
        noOfLines={4}
      >
        Welcome
      </Heading>
      <Heading as="h1" size="1" padding={10} width={'100%'}>
        From this page you can create a User Account that will assosiate you
        with your IoT device
      </Heading>
      <Button
            mt={4}
            _hover={{ background: "#14A0BF" }}
            background={'#5AC4E5'}
            colorScheme="teal"
            borderRadius={0}
            type="submit"
            variant="solid"
            p={3}
            onClick={() => {
              navigate("/signup-user");
            }}
          >
            Click here to proceed
      </Button>
      </VStack>
    </>
  );
}

export default WelcomePage;
