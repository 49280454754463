import { useEffect, useRef, useState } from "react";
import {
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  FormControl,
  InputRightElement,
  FormLabel,
} from "@chakra-ui/react";
import { FaUserAlt, FaLock } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";
import useUserSignUpHook from "../../hooks/useAuthService";

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

function ResetPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const handleShowClick = () => setShowPassword(!showPassword);
  const errRef = useRef<HTMLParagraphElement>(null);
  const [resetToken, setResetToken] = useState("");
  const [password, setPwd] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const { makeServiceRequest, data, loading, error } = useUserSignUpHook();

  useEffect(() => {
    setResetToken(searchParams.get("token") ?? "");
  }, [searchParams.get("section")]);

  function handleResetPasswordClick(e: any) {
    e.preventDefault();
    makeServiceRequest({
      url: "/auth-service/auth/changepassword",
      token: resetToken,
      password: password,
      email: localStorage.getItem("userOnBoardingEmail"),
      navigateTo: "/user-signup-success",
      successMessage: "Password updated successfully",
    });
    errRef.current !== null ? errRef.current.focus() : "";
  }

  return (
    <>
      <Stack flexDir="column" justifyContent="center" alignItems="center">
        <Box maxW={{ base: "90%", md: "468px" }}>
          <p
            ref={errRef}
            style={{
              color: error ? "red" : "inherit"
            }}
            aria-live="assertive"
          >
            {error?.response?.data?.errors
              ? error?.response?.data?.errors
              : null}
            Change password of user: {localStorage.getItem("userOnBoardingEmail") ?? ""}
          </p>
        </Box>
        <Box minW={{ base: "90%", md: "468px" }}>
          <form onSubmit={handleResetPasswordClick}>
            <Stack
              spacing={4}
              p="1rem"
              backgroundColor="whiteAlpha.900"
              boxShadow="md"
            >
              <FormControl variant="floating">
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<CFaUserAlt color="gray.300" />}
                  />
                  <Input
                    type="text"
                    placeholder=" "
                    onChange={(e) => setResetToken(e.target.value)}
                    value={resetToken}
                  />
                  <FormLabel backgroundColor="inherit" mb="8px">
                    Token
                  </FormLabel>
                </InputGroup>
              </FormControl>
              <FormControl variant="floating">
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    children={<CFaLock color="gray.300" />}
                  />
                  <Input
                    type={showPassword ? "text" : "password"}
                    placeholder=""
                    onChange={(e) => setPwd(e.target.value)}
                    value={password}
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                      {showPassword ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                  <FormLabel backgroundColor="inherit" mb="8px">
                    NEW Password
                  </FormLabel>
                </InputGroup>
              </FormControl>
              <Button
                isLoading={loading}
                loadingText="Submitting"
                borderRadius={0}
                type="submit"
                variant="solid"
                _hover={{ background: "#14A0BF" }}
                background={'#5AC4E5'}
                colorScheme="teal"
                width="full"
              >
                Submit Change
              </Button>
            </Stack>
          </form>
        </Box>
      </Stack>
    </>
  );
}

export default ResetPassword;
