import React, { useEffect, useRef, useState } from "react";
import {
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  FormControl,
  useToast,
  FormLabel,
  Select,
  Divider,
} from "@chakra-ui/react";
import { FaUserAlt, FaLock } from "react-icons/fa";
import useUserSignUpHook from "../../hooks/useAuthService";

const CFaUserAlt = chakra(FaUserAlt);
const countries = [
  'Greece',
  'Afghanistan',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Brazil',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Comoros',
  'Congo',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czech Republic',
  'Democratic Republic of the Congo',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'East Timor',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Fiji',
  'Finland',
  'France',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Grenada',
  'Guatemala',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Honduras',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Korea, North',
  'Korea, South',
  'Kosovo',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Micronesia',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'North Macedonia',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Qatar',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Togo',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Vatican City',
  'Venezuela',
  'Vietnam',
  'Yemen',
  'Zambia',
  'Zimbabwe'
];

function UserDetailsPage() {
  const errRef = useRef<HTMLParagraphElement>(null);
  const [address, setAddress] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [orgOrEspAccountId, setOrgOrEspAccountId] = useState("");
  const [country, setCounty] = useState("Greece");
  const [tin, setTin] = useState("");
  const [userStatus, setUserStatus] = useState(1);
  const [userRole, setUserRole] = useState(1);
  const [userType, setUserType] = useState(0);
  const [availableProvidersArray, setAvailableProvidersArray] = useState([]);
  const { makeServiceRequest,fetchProvidersListRequest, data, loading, error } = useUserSignUpHook();
  const toast = useToast();

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchProvidersListRequest();
    }, 3000);
  
    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
        setAvailableProvidersArray(data);
  }, [data]);

  function showMessage(
    errMsg: string,
    status:
      | "info"
      | "warning"
      | "success"
      | "error"
      | "loading"
      | undefined = "error"
  ) {
    toast({
      title: errMsg,
      position: "top-right",
      isClosable: true,
      status: status,
    });
  }

  function handleSubmit(e: any, params: any) {
    e.preventDefault();
    makeServiceRequest(params);
    errRef.current !== null ? errRef.current.focus() : "";
  }

  return (
    <>
      <Heading
        color="#5AC4E5"
        fontFamily="monospace"
        fontWeight="bold"
        mb="4"
      >customer profile</Heading>
      <Stack flexDir="column" justifyContent="center" alignItems="center">
        <Box maxW={{ base: "90%", md: "468px" }}>
          <p
            ref={errRef}
            style={{ color: error ? "red" : "inherit" }}
            aria-live="assertive"
          >
            {error?.response?.data?.errors
              ? error?.response?.data?.errors
              : null}
          </p>
        </Box>
        <Box minW={{ base: "90%", md: "468px" }}>
          <form
            onSubmit={(e) => {
              handleSubmit(e, {
                url: "user-service/userdetails",
                authId: localStorage.getItem("userOnBoardingAuthId"),
                orgOrEspAccountId: orgOrEspAccountId,
                email: localStorage.getItem("userOnBoardingEmail"),
                address: address,
                status: userStatus,
                country: country,
                zipCode: parseInt(zipCode),
                role: userRole,
                userType: userType,
                tin: tin,
                name: "",
                navigateTo: '/create-supply'
              });
            }}
          >
            <Stack
              spacing={4}
              p="1rem"
              backgroundColor="whiteAlpha.900"
              boxShadow="md"
            >
              <FormControl>
                <FormLabel mb="8px">Address</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<CFaUserAlt color="gray.300" />}
                  />
                  <Input
                    type="text"
                    required
                    placeholder="address"
                    onChange={(e) => setAddress(e.target.value)}
                    value={address}
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel mb="8px">Country</FormLabel>
                <Select onChange={(e) => setCounty(e.target.value)}>
                  {countries.map((option, index) => (
                     <React.Fragment key={index}>
                      <option key={index} value={option}>
                        {option}
                      </option>
                      {index === 0 && <Divider />}
                    </React.Fragment>
                  ))}
              </Select>
              </FormControl>
              <FormControl>
                <FormLabel mb="8px">Zip Code</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<CFaUserAlt color="gray.300" />}
                  />
                  <Input
                    type="number"
                    required
                    placeholder="zip code"
                    onChange={(e) => setZipCode(e.target.value)}
                    value={zipCode}
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel mb="8px">Electricity Provider</FormLabel>
                <InputGroup>
                  <Select
                      value={orgOrEspAccountId}
                      required
                      placeholder=""
                      onChange={(e) => setOrgOrEspAccountId(e.target.value)}
                    >
                      <option value="0"></option>
                      {availableProvidersArray?.length>0 ? 
                        availableProvidersArray.map((provider: any) => (
                            <option key={provider.id} value={provider.id}>
                            {provider.name}
                            </option>
                        )) : ''}
                  </Select>
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel mb="8px">Tin</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<CFaUserAlt color="gray.300" />}
                  />
                  <Input
                    type="text"
                    required
                    placeholder="tin"
                    onChange={(e) => setTin(e.target.value)}
                    value={tin}
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <FormLabel mb="8px">User Status</FormLabel>
                <InputGroup>
                  <Select
                      value={userStatus}
                      required
                      placeholder=""
                      onChange={(e) => setUserStatus(parseInt(e.target.value))}
                    >
                      <option value="0">Inactive</option>
                      <option value="1">Active</option>
                  </Select>
                </InputGroup>
              </FormControl>
              
              <FormControl>
                <FormLabel mb="8px">User role</FormLabel>
                <InputGroup>
                  <Select
                      value={userRole}
                      required
                      placeholder=""
                      onChange={(e) => setUserRole(parseInt(e.target.value))}
                    >
                      {/* <option value="0">Admin User</option> */}
                      <option value="1">Simple user</option>
                  </Select>
                </InputGroup>
              </FormControl>

              <FormControl>
                <FormLabel mb="8px">User type</FormLabel>
                <InputGroup>
                  <Select
                      value={userType}
                      required
                      placeholder=""
                      onChange={(e) => setUserType(parseInt(e.target.value))}
                    >
                      <option value="1">consumer</option>
                      {/* <option value="0">Operator User</option> */}
                  </Select>
                </InputGroup>
              </FormControl>

              <Button
                isLoading={loading}
                loadingText="Submitting"
                borderRadius={0}
                type="submit"
                variant="solid"
                _hover={{ background: "#14A0BF" }}
                background={'#5AC4E5'}
                colorScheme="teal"
                width="full"
              >
                create user profile
              </Button>
            </Stack>
          </form>
        </Box>
      </Stack>
    </>
  );
}

export default UserDetailsPage;
