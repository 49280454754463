import { CheckCircleIcon } from "@chakra-ui/icons";
import { Box, Text } from "@chakra-ui/react";

function OnboardingSuccessPage() {
  return (
    <Box textAlign="center" mt="10">
      <CheckCircleIcon boxSize="10" color="green.500" />
      <Text fontSize="2xl" fontWeight="bold" mt="5">
        Customer Created Successfuly!
      </Text>
    </Box>
  );
}

export default OnboardingSuccessPage;