import { useEffect, useRef, useState } from "react";
import {
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  FormControl,
  useToast,
} from "@chakra-ui/react";
import { FaUserAlt, FaLock } from "react-icons/fa";
import useUserSignUpHook from "../../hooks/useAuthService";
import { useLocation } from "react-router-dom";

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

function VerifyAccount() {
  const location = useLocation();
  const errRef = useRef<HTMLParagraphElement>(null);

  const toast = useToast();
  const { makeServiceRequest, data, loading, error } = useUserSignUpHook();
  const queryParams = new URLSearchParams(location.search);
  const verifyUserToken = queryParams.get('key');

  const [verificationToken, setverificationToken] = useState(verifyUserToken ?? "");

  function handleSubmit(e: any, params: any) {
    e.preventDefault();
    makeServiceRequest(params);
    errRef.current !== null ? errRef.current.focus() : "";
    if (data) {
      console.log(data);
    }
  }

  function showMessage(
    errMsg: string,
    status:
      | "info"
      | "warning"
      | "success"
      | "error"
      | "loading"
      | undefined = "error"
  ) {
    toast({
      title: errMsg,
      position: "top-right",
      isClosable: true,
      status: status,
    });
  }

  return (
    <>
    <Stack flexDir="column" justifyContent="center" alignItems="center">
        <Box maxW={{ base: "90%", md: "468px" }}>
      <p
        ref={errRef}
        style={{ color: error ? "red" : "inherit"}}
        aria-live="assertive"
      >
        {error?.response?.data?.errors ? error?.response?.data?.errors : null}
      </p>
      </Box>
      <Box minW={{ base: "90%", md: "468px" }}>
        <form
          onSubmit={(e) => {
            handleSubmit(e, {
              url: "/auth-service/auth/confirmationemail",
              key: verificationToken,
              navigateTo: "/login-user",
              successMessage: "User added successfully",
            });
          }}
        >
          <Stack
            spacing={4}
            p="1rem"
            backgroundColor="whiteAlpha.900"
            boxShadow="md"
          >
            <FormControl>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  color="gray.300"
                  children={<CFaLock color="gray.300" />}
                />
                <Input
                  type="text"
                  disabled
                  required
                  placeholder="Verification Code"
                  onChange={(e) => setverificationToken(e.target.value)}
                  value={verificationToken}
                />
              </InputGroup>
            </FormControl>
            <Button
              isLoading={loading}
              loadingText="Submitting"
              borderRadius={0}
              type="submit"
              variant="solid"
              _hover={{ background: "#14A0BF" }}
              background={'#5AC4E5'}
              colorScheme="teal"
              width="full"
            >
              Verify Account
            </Button>
          </Stack>
        </form>
      </Box>
      </Stack>
    </>
  );
}

export default VerifyAccount;
